<template>
  <!-- side popup modal -->
  <form
    method="post"
    @submit.prevent="submitHandler()"
    enctype="multipart/form-data"
  >
    <div class="form-field-group">
      <h3 class="form-title">
        <template v-if="skill.Id">Edit</template>
        <template v-else>Add</template> Skill
      </h3>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="skill-name">Name</label>
          <div class="form-field">
            <input
              class="form-control"
              id="skill-name"
              placeholder="Name"
              type="text"
              v-model.trim="skill.name"
            />
            <span v-if="submitted && v$.skill.name.required.$invalid" class="error">* required.</span>
            <span
              v-if="v$.skill.name.maxLength.$invalid"
              class="error"
              >Skill Name should have at most
              {{ v$.skill.name.maxLength.$params.max }}.</span
            >
          </div>
        </div>
      </div>
    </div>
      
    <div class="form-controls">
      <div class="button-row d-flex justify-content-end">
        <button
          class="ml-auto secondary-btn"
          title="Cancel"
          type="button"
          @click.prevent="$emit('close')"
        >
          CANCEL
        </button>
        <button
          class="ml-3 js-btn-next primary-btn"
          type="submit"
          @click.prevent="submitHandler()"
        >
          <template v-if="skill.Id">Update</template>
          <template v-else>Create</template>
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import '@vuepic/vue-datepicker/dist/main.css'
import useVuelidate from "@vuelidate/core";
import {
  required,
  maxLength,
} from "@vuelidate/validators";
import common from "../mixins/common";
import profilePrefix from "../services/profilePrefix.service";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    editSkill : {
      type: Object,
      required: false,
    },
    categories : {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  mixins: [common],
  data: () => ({
    submitted: false,
    skill: {
      Id: "",
      name: "",
      type: "skill",
    },
  }),
  validations() {
    return {
      skill: {
        name: {
          required,
          maxLength: maxLength(150),
        },
      },
    };
  },
  created() {
    const vm = this;
    if(vm.editSkill && 'Id' in vm.editSkill && 'name' in vm.editSkill) {
      vm.skill = vm.editSkill;
    }
  },
  methods: {
    async submitHandler() {
      let vm = this;

      vm.submitted = true;
      vm.v$.$touch();

      const isValidate = await vm.v$.$validate();

      if (!isValidate) return;

      vm.skill.type = 'skill';

      let formData = vm.skill;
      
      if (vm.skill.Id) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await profilePrefix.update(
            formData,
            vm.skill.Id
          );
          vm.toastMessage(response.message, "success");
          vm.$emit("refreshList");
          vm.$emit("close");
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      } else {
        try {
          delete formData.Id;
          vm.$store.commit("loadingStatus", true);
          const response = await profilePrefix.create(formData);
          vm.toastMessage(response.message, "success");
          vm.$emit("refreshList");
          vm.$emit("close");
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
  },
};
</script>
