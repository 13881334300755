import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class ProfilePrefixService {

  async get(type) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'application/json';
    const response = await axios
      .post(`${API_URL}get-profile-prefix`, {type}, config);
    return response.data;
  }

  async create(data) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'application/json';
    const response = await axios
      .post(`${API_URL}add-profile-prefix`, data, config);
    return response.data;
  }

  async update(data, Id) {
    const config = {
      headers: authHeader()
    };

    config.headers['Content-Type'] = 'application/json';
    const response = await axios
      .post(`${API_URL}update-profile-prefix/${Id}`, data, config);
    return response.data;
  }

}

export default new ProfilePrefixService();